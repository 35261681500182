export default function setupAxios(axios, store) {
  // axios.defaults.baseURL = 'https://iot-selec-3tux762r3q-de.a.run.app/' // base url of API
  // axios.defaults.baseURL =
  //   'http://selec-iot-backend-api-load-bal-394607317.ap-south-1.elb.amazonaws.com/' // base url of API
  // axios.defaults.baseURL = 'http://localhost:8080/' // base url of API when we run it on local
  axios.defaults.baseURL = "https://api.selec-iot.com/"

  // axios.defaults.withCredentials = true; // need to set this to true to send Cookie in request headers
  axios.interceptors.request.use(
    (config) => {

      const state = store.getState()
      const clientId = state?.client?.payload?._id
      const userRole = state?.auth?.user?.role
      const authToken = state?.auth?.authToken

      if (clientId && ['selecUser'].includes(userRole)) {
        config.headers.clientId = `${clientId}`
      }

      if (
        authToken &&
        config.url.indexOf('s3.') === -1 &&
        config.url.indexOf('googleapis.') === -1
      ) {
        config.headers.Authorization = `Bearer ${authToken.token}`
        if (authToken.clientId) {
          config.headers.clientId = `${authToken.clientId}`
        }
      }
      return config
    },
    (err) => Promise.reject(err),
  )

  axios.interceptors.response.use(
    (resp) => {
      return resp.data || resp
    },
    (err) => {
      switch (err?.response?.status) {
        case 400:
          return Promise.reject(
            err.response ? err.response.data || err.response : err,
          )
        case 455:
          window.location = '/logout'
          return Promise.resolve({})
        case 401:
          if (err.response?.data?.message === 'not permitted') {
            return Promise.reject(err.response ? (err.response.data || err.response || err.response.data.message) : err)
          }
          window.location = '/logout'
          return
        // return Promise.reject(
        //   err.response ? err.response.data || err.response : err,
        // )
        default:
          return Promise.reject(
            err.response ? err.response.data || err.response : err,
          )
      }
    },
  )
}
